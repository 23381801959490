<template>
  <v-container>
    <v-row>
      <v-col>
        <div>
          <h1 class="mb-4">Terms and Conditions</h1>
          <p>
            Ontrack Technologies Private Limited (Ontrack) is a private limited
            company registered in the state of Karnataka, India. The registered
            address of the company is 3rd Floor, Salarpuria Tower 1, Hosur Road,
            Koramangala, Bengaluru – 560095.
          </p>
        </div>
        <div>
          <ul>
            <li>
              Ontrack is a distributor of two-wheeler loans for certain banks
              and NBFCs.
            </li>
            <li>
              Ontrack Finance is an offering on the Ontrack Applications and
              Websites through which Ontrack facilitates provision of loans from
              the lenders to its users.
            </li>
            <li>
              Ontrack is merely a facilitator and not a lender to its users.
            </li>
          </ul>
        </div>
        <div class="mt-4">
          <h2>Terms</h2>
          <ol class="mt-4">
            <li>
              Users hereby agree that the users’ credit bureau information on a
              non-individually identifiable basis may be shared with the
              Lenders.
            </li>
            <li>
              Based on the User’s credit information and on the Lender’s
              preliminary lending criteria, Ontrack may show loan offerings to
              the user. Provided however, Ontrack may choose to introduce, vary
              or withdraw such offers at any time and Ontrack does not guarantee
              or assure any user of loan offerings being available to them.
            </li>
            <li>
              The On-Road-Price are indicative and actual prices may vary at the
              dealership.
            </li>
            <li>
              Selection of Model, Color and Variant are for understanding loan
              requirements only, and further selection to be made at the
              dealership.
            </li>
            <li>
              Bikes are subject to availability at the dealership, Ontrack is
              not the seller but merely a facilitator of the sale and it’s
              financing.
            </li>
            <li>
              The bike images displayed are for representation purpose only,
              actual product delivered may differ from the images seen on
              Ontrack’s application and website.
            </li>
            <li>
              Model specifications are inspected at the time of manufacture by
              the original equipment manufacturer. Individual vehicle
              specification subject to vary.
            </li>
            <li>
              Final loan approval is subject to providing necessary documents to
              the lender.
            </li>
            <li>
              In case of purchase, the delivery of the vehicle shall be done by
              the tied-up dealership, and not Ontrack Technologies Private
              Limited. Any issue with the vehicle or it’s parts must be taken up
              directly by to the dealership or the original equipment
              manufacturer.
            </li>
            <li>
              The loan amount mentioned on the Ontrack’s application may vary
              from the actual loan provided by the lender, this assessment will
              be carried out by the lender at their sole discretion and the
              responsibility of the communication will be borne by the lender.
            </li>
          </ol>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "NewComponent"
};
</script>
<style scoped></style>
